import React, { useState } from 'react';
import type { Fields, FieldErrors, StringFieldDefinition, Field } from '@wilm/shared-types/validation-rules/types';
import Typography from 'components/commercetools-ui/atoms/typography';
import { validate } from '@wilm/shared-types/validation-rules';
import Input from 'components/commercetools-ui/atoms/input';
import { fields } from '@wilm/shared-types/validation-rules/payment';
import { useFormat } from 'helpers/hooks/useFormat';

const PoNumberField = () => {
    const [formErrors, setFormErrors] = useState<FieldErrors>({});

    const handleFieldBlur = (field: StringFieldDefinition, fields: Fields) => {
        const error = validate(field, fields);
        setFormErrors(prevErrors => ({
            ...prevErrors,
            [field.name]: error
        }));
    };

    const { formatMessage: formatFieldErrorsMessage } = useFormat({ name: 'field-errors' });
    const { formatMessage: formatFieldLabelsMessage } = useFormat({ name: 'field-labels' });

    const [field, setField] = useState(fields.poNumber);

    console.log(field);
    const errorMessage = formErrors?.[field.name]?.message;

    const formattedErrorMessage = errorMessage
        ? formatFieldErrorsMessage({
              id: errorMessage,
              defaultMessage: errorMessage
          })
        : '';

    const handleFieldChange = (field: Field, value: string) => {
        setField({
            ...field,
            value
        } as any);
    };

    console.log(field);

    return (
        <div className="border-checkout-border  border-b pb-16 lg:border-0 lg:pb-15">
            <Typography className="mb-16 text-20" as="h2">
                PO Number
            </Typography>

            <div>
                <Input
                    id="po-number"
                    name={field.name}
                    type="text"
                    label={formatFieldLabelsMessage({
                        id: `payment.${field.name}.label`,
                        defaultMessage: field.name
                    })}
                    value={field.value}
                    minLength={field.validation.minLength}
                    maxLength={field.validation.maxLength}
                    required={field.validation.required}
                    onChange={e => {
                        handleFieldChange(field, e.target.value);
                    }}
                    onBlur={() => {
                        handleFieldBlur(field, fields);
                    }}
                    errorMessage={formattedErrorMessage}
                />
            </div>
        </div>
    );
};

export default PoNumberField;
