import type { Address } from '../account/Address';
import type { Money } from '../product/Money';
import type { ShippingMethod } from './ShippingMethod';
import type { LineItem } from './LineItem';
import type { Payment } from './Payment';
import type { Discount } from './Discount';
import type { Tax } from './Tax';
import type { ShippingInfo } from './ShippingInfo';

export interface Cart {
    cartId: string;
    cartVersion?: string;
    lineItems?: LineItem[];
    email?: string;
    birthday?: Date;
    shippingInfo?: ShippingInfo; // Info of the shipping method selected by the customer
    availableShippingMethods?: ShippingMethod[]; // Available shipping methods for this cart
    shippingAddress?: Address;
    billingAddress?: Address;
    sum?: Money;
    payments?: Payment[];
    discountCodes?: Discount[];
    taxed?: Tax;
    customerId?: string;
    discountedAmount?: Money;
}

export interface CartInventoryCheckType extends CartInventoryCheckResult {
    isError: boolean;
}

export interface CartInventoryCheckResult {
    hasOutOfStockItems: boolean;
    outOfStockItems: OutOfStockItems;
}

export type OutOfStockItems = Record<string, CartInventoryItemErrorType>;

export interface CartInventoryItemErrorType {
    message: CartInventoryItemErrorMessageEnum;
    availableQuantity: number;
}
export enum CartInventoryItemErrorMessageEnum {
    OUT_OF_STOCK = 'outofstock',
    NOT_ENOUGH_AVAILABLE = 'notenoughavailable'
}
