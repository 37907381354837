import type { Group } from './Group';
import type { Address } from './Address';
import type { AccountToken } from './AccountToken';

export enum AccountStatus {
    OPEN = 'open',
    PREPAYMENT_ONLY = 'prepayment',
    BLOCKED = 'blocked'
}

export interface Account {
    accountId?: string;
    email: string;
    salutation?: string;
    firstName?: string;
    lastName?: string;
    birthday?: Date;
    password?: string;
    groups?: Group[];
    confirmationToken?: AccountToken;
    confirmed?: boolean;
    addresses?: Address[];
    apiToken?: string;
    token?: Token;
    isB2B?: boolean;
    status?: AccountStatus;
    isExisting?: boolean;
    redirectToLogin?: boolean;
    phoneMobile?: string;
    existingSource?: string;
    errors?: Record<string, string>;
    statusOverride?: AccountStatus;
    isStaged?: boolean;
}

interface Token {
    tokenUpdated: boolean;
    alias: string;
    value: string;
    paymentToken: string;
    instrumentIdentifier: string;
    addressId: string;
    cardType: string;
    cardNumber: string;
    cardExpiryYear: string;
    cardExpiryMonth: string;
    currencyCode: string;
    merchantId: string;
}
