'use client';
import { PaymentLinkStatus, type PaymentLinkInfoType } from '@wilm/shared-types/payment-link/PaymentLink';
import SalesLinkPayment from 'components/sales-link/organisms/payment';
import type { TasticProps } from 'frontastic/tastics/types';
import type { TermsAndConditionsData } from 'components/commercetools-ui/organisms/checkout';
import type { Reference } from 'types/reference';
import SalesLinkError from 'components/sales-link/organisms/error';

export interface SalesLinkPaymentTastic {
    paymentLinkInfo: PaymentLinkInfoType;
    termsAndConditions: TermsAndConditionsData[];
    termsAndConditionsSecond: TermsAndConditionsData[];
    termsAndConditionsThird: TermsAndConditionsData[];
    contactUsLink: Reference;
}

const SalesLinkPaymentTastic = (props: TasticProps<SalesLinkPaymentTastic>) => {
    const paymentLinkInfo: PaymentLinkInfoType = props.dataToTastic?.paymentLinkInfo;
    const status = paymentLinkInfo.status;
    const termsAndConditions = props.data.termsAndConditions;
    const termsAndConditionsSecond = props.data.termsAndConditionsSecond;
    const termsAndConditionsThird = props.data.termsAndConditionsThird;
    const contactUsLink = props.data.contactUsLink;

    if (status === PaymentLinkStatus.TOKEN_NOT_EXIST) {
        return <SalesLinkError title="Resource not found" message="The requested resource was not found." />;
    }

    if (status === PaymentLinkStatus.TOKEN_EXPIRED) {
        return <SalesLinkError title="Link expired" message="Payment link that you are trying to access has expired." />;
    }

    if (status === PaymentLinkStatus.READY_FOR_PAYMENT) {
        return (
            <SalesLinkPayment
                paymentLinkInfo={paymentLinkInfo}
                termsAndConditions={termsAndConditions}
                termsAndConditionsSecond={termsAndConditionsSecond}
                termsAndConditionsThird={termsAndConditionsThird}
                contactUsLink={contactUsLink}
            />
        );
    }
};
export default SalesLinkPaymentTastic;
