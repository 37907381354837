import type { BundleComponents, LineItem } from '@wilm/shared-types/cart/LineItem';
import type { UseBundleReturn } from './types';

const useBundle = (lineItems: LineItem[]): UseBundleReturn => {
    const nonBundleItems = lineItems?.filter(lineItem => !lineItem.parentBundleProductLineItemId);
    const bundleComponents: BundleComponents =
        lineItems?.reduce((acc, lineItem) => {
            if (lineItem.parentBundleProductLineItemId) {
                if (!acc[lineItem.parentBundleProductLineItemId]) {
                    acc[lineItem.parentBundleProductLineItemId] = [];
                }

                if (lineItem.totalPrice?.centAmount && lineItem.totalPrice?.centAmount > 0 && !lineItem.skipProvisioning) {
                    acc[lineItem.parentBundleProductLineItemId].push(lineItem);
                }
            }
            return acc;
        }, {} as BundleComponents) || {};
    const totalItems = lineItems.filter(lineItem => !lineItem.parentBundleProductLineItemId).length;
    return { nonBundleItems, bundleComponents, totalItems };
};

export default useBundle;
