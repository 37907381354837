import type { FC } from 'react';
import React from 'react';
import type { Variant } from '@commercetools/frontend-domain-types/product';
import type { Product } from '@wilm/shared-types/product';
import { BundleMode } from '@wilm/shared-types/product/Bundle';
import BundleComponentSelectorTile from './tile';
import type { LineItemAddToCartAttributes, BundleLineItemError } from '@wilm/shared-types/sales-link/SalesLinkCart';
import Button from 'components/commercetools-ui/atoms/button';
import { useFormat } from 'helpers/hooks/useFormat';

interface BundleComponentSelectorProps {
    mode: BundleMode;
    variant: Variant;
    bundleErrors: BundleLineItemError;
    onAddToCart: (variant: Variant, attributes?: LineItemAddToCartAttributes) => void;
}

const BundleComponentSelector: FC<BundleComponentSelectorProps> = ({ mode, variant, bundleErrors, onAddToCart }) => {
    const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
    const [selectedAttributes, setSelectedAttributes] = React.useState<LineItemAddToCartAttributes>({
        selectedBundleComponentVariantSKUs: {},
        selectedCommencementDate: ''
    });

    const components = variant.attributes?.bundleComponentsProducts;

    const onBundleComponentSelect = (selectedProduct: Product, selectedVariant: Variant) => {
        if (mode === BundleMode.COMMENCEMENT_DATE) {
            setSelectedAttributes({
                ...selectedAttributes,
                selectedBundleComponentVariantSKUs: {
                    [selectedProduct.productId!]: selectedVariant.sku
                },
                selectedCommencementDate: selectedVariant.attributes?.startDate.split('T')[0]
            });
        } else if (mode === BundleMode.SELECT_COMPONENTS) {
            setSelectedAttributes({
                ...selectedAttributes,
                selectedBundleComponentVariantSKUs: {
                    ...(selectedAttributes.selectedBundleComponentVariantSKUs as Record<string, string>),
                    [selectedProduct.productId!]: selectedVariant.sku
                }
            });
        }
    };

    const handleAddToCart = () => {
        onAddToCart(variant, selectedAttributes);
    };

    return (
        <div className="max-h-screen overflow-auto p-40">
            {components.map((component: Product) => (
                <BundleComponentSelectorTile
                    key={component.productId}
                    product={component}
                    errors={bundleErrors}
                    selectedSkus={selectedAttributes.selectedBundleComponentVariantSKUs as Record<string, string>}
                    onSelect={onBundleComponentSelect}
                />
            ))}
            <div className="flex justify-end">
                <Button variant="primary" onClick={handleAddToCart}>
                    Add to cart
                </Button>
            </div>
            <div className="flex justify-end">
                {bundleErrors?.error.code && <div className="text-red-500">{formatErrorMessage({ id: bundleErrors.error.code })}</div>}
            </div>
        </div>
    );
};

export default BundleComponentSelector;
