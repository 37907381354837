import type { FC } from 'react';
import React from 'react';
import type { LineItem, MembershipAutoRenewalType } from '@wilm/shared-types/cart/LineItem';
import Checkbox from 'components/commercetools-ui/atoms/checkbox';
import Price from 'components/commercetools-ui/atoms/product-types/price';
import ProductName from 'components/commercetools-ui/atoms/product-types/product-name';
import Quantity from 'components/commercetools-ui/atoms/product-types/quantity';
import DelegateCheckbox from 'components/commercetools-ui/organisms/cart/components/delegate-checkbox';
import type { HandleIsRenewableChangeType } from 'frontastic/hooks/useCart/types';
interface MembershipCartItemProps {
    item: LineItem;
    duration: { label: string; value: number; hidden: boolean };
    counterClassName: string;
    updateCartItem?: (newQuantity: number) => Promise<void>;
    removeItem?: (lineItemId: string) => void;
    hideAttributes?: boolean;
    disabledQuantity?: boolean;
    showPrice?: boolean;
    showQuantity?: boolean;
    showQuantityEdit?: boolean;
    handleIsRenewableChange?: HandleIsRenewableChangeType;
    membershipAutoRenewal?: MembershipAutoRenewalType;
    autoRenewalPolicy?: string;
    inventoryCheckError?: string;
    showDelegateCheckbox?: boolean;
}

const MembershipCartItem: FC<MembershipCartItemProps> = ({
    item = {} as LineItem,
    duration,
    counterClassName = '',
    updateCartItem = () => {},
    removeItem,
    hideAttributes = false,
    disabledQuantity = false,
    showPrice = true,
    showQuantity = false,
    showQuantityEdit = true,
    handleIsRenewableChange,
    membershipAutoRenewal,
    autoRenewalPolicy,
    inventoryCheckError,
    showDelegateCheckbox
}) => {
    return (
        <>
            <ProductName lineItemId={item.lineItemId} removeItem={removeItem} productName={item.name!} />

            {/* Price */}
            {showPrice && <Price price={item.price as number} discountedPrice={item.discountedPrice as number} />}

            {/* Quantity */}
            <Quantity
                count={item.count ?? 1}
                counterClassName={counterClassName}
                updateCartItem={updateCartItem}
                disabledQuantity={disabledQuantity}
                showQuantityEdit={showQuantityEdit}
                showQuantity={showQuantity}
                inventoryCheckError={inventoryCheckError}
            />

            {/* Duration */}
            {!hideAttributes && (
                <div className="mt-10">
                    {!duration.hidden && (
                        <div className="mb-5">
                            <div>
                                <span className="text-md font-bold">{duration.label}:</span>{' '}
                                <span className={'text-md'}>{duration.value} months</span>
                            </div>
                        </div>
                    )}
                    {item.variant?.attributes?.isRenewable &&
                    autoRenewalPolicy &&
                    handleIsRenewableChange &&
                    item.price?.centAmount &&
                    item.price?.centAmount > 0 &&
                    !item?.variant?.attributes?.isComplimentaryProduct ? (
                        <Checkbox
                            smallLabel={true}
                            label={autoRenewalPolicy}
                            name={item.lineItemId}
                            onChange={({ name, checked }) => {
                                handleIsRenewableChange(name, checked);
                            }}
                            checked={membershipAutoRenewal?.[item.lineItemId]}
                        />
                    ) : (
                        <></>
                    )}

                    {showDelegateCheckbox && <DelegateCheckbox itemId={item.lineItemId} />}
                </div>
            )}
        </>
    );
};

export default MembershipCartItem;
