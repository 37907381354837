'use client';

import React, { createContext } from 'react';
import Header from 'components/commercetools-ui/organisms/header';
import type { HeaderProps, Market } from 'components/commercetools-ui/organisms/header/types';
import { MarketProvider } from 'context/market';
import { useBrandSettingsContext } from 'providers/brand-settings';
import type { TasticProps } from '../types';

const initialMarketState = {
    market: {} as Market,
    markets: [] as Market[],
    handleMarket: {} as (market: Market) => void
};
export const MarketContext = createContext(initialMarketState);

const HeaderTastic = ({ data }: TasticProps<HeaderProps>) => {
    const { wishlist } = useBrandSettingsContext();
    return (
        <MarketProvider>
            <div id="header-container" className=" z-50 w-full">
                <Header
                    categories={data.categories}
                    menuCategories={data.menuCategories}
                    logo={data.logo}
                    logoLink={data.logoLink}
                    emptyCartImage={data.emptyCartImage}
                    accountLinkLabel={data.accountLinkLabel}
                    accountLink={data.accountLink}
                    accountLabel={data.accountLabel}
                    accountLabelLogged={data.accountLabelLogged}
                    accountLogoutLabel={data.accountLogoutLabel}
                    wishlistLinkLabel={wishlist?.enabled ? data.wishlistLinkLabel : undefined}
                    wishlistLink={wishlist?.enabled ? data.wishlistLink : undefined}
                    cartLabel={data.cartLabel}
                    searchLabel={data.searchLabel}
                    menuLabel={data.menuLabel}
                    socialMedia={data.socialMedia}
                    emptyCartTitle={data.emptyCartTitle}
                />
            </div>
        </MarketProvider>
    );
};
export default HeaderTastic;
