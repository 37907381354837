import React, { useState } from 'react';
import type { PaymentLinkInfoType } from '@wilm/shared-types/payment-link/PaymentLink';
import Button from 'components/commercetools-ui/atoms/button';
import Secure from 'components/commercetools-ui/organisms/checkout/components/secure';
import Address from 'components/sales-link/organisms/payment/address';
import type { CheckboxProps } from 'components/commercetools-ui/atoms/checkbox';
import CreditCartFields from 'components/sales-link/organisms/payment/credit-cart';
import PoNumberField from 'components/sales-link/organisms/payment/po-number';
import OrderSummary from 'components/sales-link/organisms/payment/summary';
import type { Reference } from 'types/reference';
import Link from 'components/commercetools-ui/atoms/link';

import TermsAndConditions, {
    type TermsAndConditionsType
} from 'components/commercetools-ui/organisms/checkout/components/terms-and-conditions';
import type { TermsAndConditionsData } from 'components/commercetools-ui/organisms/checkout';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop } from 'helpers/utils/screensizes';

export interface TermsAndConditionsAll {
    termsAndConditions: TermsAndConditionsData[];
    termsAndConditionsSecond: TermsAndConditionsData[];
    termsAndConditionsThird: TermsAndConditionsData[];
    contactUsLink: Reference;
}

export interface Props extends TermsAndConditionsAll {
    paymentLinkInfo: PaymentLinkInfoType;
}

const SalesLinkPayment: React.FC<Props> = ({
    paymentLinkInfo,
    termsAndConditions,
    termsAndConditionsSecond,
    termsAndConditionsThird,
    contactUsLink
}) => {
    const order = paymentLinkInfo.order;
    const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
    const [isDesktopSize] = useMediaQuery(desktop);
    const { formatMessage: formatOrdersMessage } = useFormat({ name: 'orders' });
    const hasRequiredShipping = order?.lineItems?.some(lineItem => lineItem.variant?.attributes?.isShippingRequired);

    const termsAndConditionsAll: TermsAndConditionsData[][] = [termsAndConditions, termsAndConditionsSecond, termsAndConditionsThird];
    const termsAndConditionsSkeleton: TermsAndConditionsType = {};

    for (const [key, terms] of Object.entries(termsAndConditionsAll)) {
        const termForRightCountry = terms?.find(el => el.country === order?.billingAddress?.country || el.country === 'default');
        if (termForRightCountry) {
            termsAndConditionsSkeleton[`termsAndConditions${key}`] = {
                text: termForRightCountry.markdownText,
                checked: false
            };
        }
    }

    const [termsAndConditionsMaped, setTermsAndConditionsMaped] = useState(termsAndConditionsSkeleton);

    const isFinalStep = false; // @TODO

    const handleTermsChange: CheckboxProps['onChange'] = ({ checked, name }) => {
        setTermsAndConditionsMaped(prev => ({
            ...prev,
            [name]: {
                ...prev[name],
                checked
            }
        }));
    };

    return (
        <div className="min-h-screen lg:bg-checkout-bg lg:pb-40">
            <div className="lg:px-40">
                <Secure />
                <div className="flex flex-col items-start lg:flex-row lg:gap-40">
                    {/* Main content */}
                    <div className="mt-20 w-full  lg:mt-0 lg:grow lg:px-0">
                        <div className="bg-white p-16 md:p-30">
                            <Address
                                address={order?.billingAddress}
                                title={formatOrdersMessage({
                                    id: 'billing.address',
                                    defaultMessage: 'Billing address:'
                                })}
                                containerClassName="border-checkout-step-border p-0 text-md md:py-13 lg:border lg:p-15"
                                wrapperClassName="border-checkout-border mb-30 border-b pb-16 lg:border-0 lg:pb-15"
                            />
                            {hasRequiredShipping && (
                                <Address
                                    address={order?.shippingAddress}
                                    title={formatOrdersMessage({
                                        id: 'shipping.address',
                                        defaultMessage: 'Shipping address:'
                                    })}
                                    containerClassName="border-checkout-step-border p-0 text-md md:py-13 lg:border lg:p-15"
                                    wrapperClassName="border-checkout-border mb-30 border-b pb-16 lg:border-0 lg:pb-15"
                                />
                            )}
                            <PoNumberField />
                            <CreditCartFields />
                        </div>

                        <div className="mt-30 hidden lg:block">
                            <TermsAndConditions termsAndConditions={termsAndConditionsMaped} handleTermsChange={handleTermsChange} />
                        </div>
                    </div>
                    {/* Sidebar */}

                    <OrderSummary
                        className="mb-20 bg-white px-16 max-lg:w-full md:mt-24 lg:mt-0 lg:min-w-[30%]  lg:p-40 lg:py-36"
                        order={order}
                        title="Order summary"
                        sFinalStep={isFinalStep}
                        classNames={{
                            totalAmount: 'md:text-18 font-medium flex items-center justify-between'
                        }}
                        asSkeleton={!order?.orderId}
                        button={
                            <>
                                {isFinalStep && termsAndConditionsAll && !isDesktopSize && (
                                    <div className="mb-16 lg:hidden">
                                        <TermsAndConditions
                                            termsAndConditions={termsAndConditionsMaped}
                                            handleTermsChange={handleTermsChange}
                                        />
                                    </div>
                                )}
                                <Button
                                    variant="primary"
                                    disabled={!isFinalStep}
                                    className="w-full"
                                    type="submit"
                                    // onClick={}
                                >
                                    {formatCartMessage({ id: 'complete.purchase', defaultMessage: 'Complete purchase' })}
                                </Button>
                            </>
                        }
                    />
                </div>
                {contactUsLink && (
                    <div className="border-checkout-border fixed bottom-0 right-16 z-10 rounded-t-lg border bg-primary-black px-12 py-8 text-white lg:right-40">
                        <Link className="text-md font-bold" link={contactUsLink}>
                            Contact Us
                        </Link>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SalesLinkPayment;
