import type { FC } from 'react';
import React from 'react';
import Link from 'components/commercetools-ui/atoms/link';
import type { Reference } from 'types/reference';
import Image from 'frontastic/lib/image';
import type { ImageProps } from 'frontastic/lib/image/types';
import { tablet } from 'helpers/utils/screensizes';

export interface Props {
    logo: ImageProps;
    logoLink: Reference;
    imageClassName?: string;
    onClick?: () => void;
}

const HeaderLogo: FC<Props> = ({ logoLink, logo, imageClassName, onClick }) => {
    return (
        <Link className={imageClassName} link={logoLink} onClick={onClick}>
            <Image
                media={logo?.media}
                style={{ objectFit: 'contain', objectPosition: 'left' }}
                alt={logo?.title}
                loading="eager"
                sizes={`(min-width: ${tablet}px) 278px, 150px`}
                priority
            />
        </Link>
    );
};

export default HeaderLogo;
