import { format, subMonths } from 'date-fns';

export const calculateAndFormatMembershipStartDate = (endDate: string | number | Date, monthsToSubstract: number) => {
    const startDate = endDate && monthsToSubstract && subMonths(new Date(endDate), monthsToSubstract);
    return startDate && format(startDate, 'dd/MM/yyyy');
};

export const formatDate = (date: string | number | Date) => {
    return format(new Date(date), 'dd/MM/yyyy');
};
