import React, { useMemo } from 'react';
import type { Variant } from '@wilm/shared-types/product';
import type { Product } from '@wilm/shared-types/product/Product';
import type { BundleLineItemError } from '@wilm/shared-types/sales-link/SalesLinkCart';
import Button from 'components/commercetools-ui/atoms/button';
import { formatDate } from 'helpers/dateHelpers';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { useFormat } from 'helpers/hooks/useFormat';
import Typography from 'components/commercetools-ui/atoms/typography';

export interface Props {
    product: Product;
    selectedSkus: Record<string, string>;
    errors: BundleLineItemError;
    onSelect: (selectedProduct: Product, selectedVariant: Variant) => void;
}

const BundleComponentSelectorTile: React.FC<Props> = ({ product, selectedSkus, errors, onSelect }) => {
    const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
    const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
    const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });

    const name = product.name;
    const key = product.key;
    const needsSelection = errors?.missingSelectedComponentIds.includes(product.productId!);
    const formattedVariants = useMemo(() => {
        return product.variants?.map(variant => {
            const alreadySelected = Object.values(selectedSkus).includes(variant.sku);
            const outOfStock = variant.availableQuantity! <= 0 && !variant.attributes?.isInventoryPerpetual;
            let selectButtonText = formatCommonMessage({ id: 'select', defaultMessage: 'Select' });
            if (alreadySelected) {
                selectButtonText = formatCommonMessage({ id: 'selected', defaultMessage: 'Selected' });
            }
            if (outOfStock) {
                selectButtonText = formatCommonMessage({ id: 'outOfStock', defaultMessage: 'Out of stock' });
            }
            const selectionDisabled = alreadySelected || outOfStock;

            const actions = {
                label: formatCommonMessage({ id: 'select', defaultMessage: 'Select' }),
                value: (
                    <div className="mt-6 flex flex-col gap-4 sm:flex-row sm:gap-0 xl:justify-center">
                        <Button
                            className="w-full md:w-1/2"
                            variant="primary"
                            size="xs"
                            onClick={() => onSelect(product, variant)}
                            disabled={selectionDisabled}
                        >
                            {selectButtonText}
                        </Button>
                        <div className="mt-4 flex w-40 items-center text-center sm:mt-0 md:ml-6 md:mt-0">
                            {alreadySelected && <CheckCircleIcon className="w-20" />}
                        </div>
                    </div>
                )
            };

            const attributesToShow = [
                {
                    label: formatProductMessage({ id: 'shortDescription', defaultMessage: 'Short description' }),
                    value: variant.attributes?.shortDescription ?? '-'
                },
                {
                    label: formatProductMessage({ id: 'startDate', defaultMessage: 'Start date' }),
                    value: variant.attributes?.startDate ? formatDate(variant.attributes.startDate) : '-'
                },
                {
                    label: formatProductMessage({ id: 'endDate', defaultMessage: 'End date' }),
                    value: variant.attributes?.endDate ? formatDate(variant.attributes.endDate) : '-'
                },
                // cohort
                {
                    label: formatProductMessage({ id: 'cohort', defaultMessage: 'Cohort' }),
                    value: variant.attributes?.administrateEventCohort ?? '-'
                },
                // variant id
                {
                    label: formatProductMessage({ id: 'variant.sku', defaultMessage: 'Variant SKU' }),
                    value: variant.sku
                }
            ];

            return [...attributesToShow, actions];
        });
    }, [product, selectedSkus, onSelect]);

    return (
        <div className="mb-16">
            <p className="bg-gray-200 p-10 font-bold">
                {name} {key}
            </p>
            {formattedVariants.map((variant, variantIndex) => (
                <div key={'variant-' + variantIndex} className="mx-16 mb-16 grid-cols-2 gap-4 border-t xl:mb-0 xl:grid xl:grid-cols-6">
                    {variant.map((attribute, attributeIndex) => (
                        <div key={'attribute-' + attributeIndex} className="mb-3 grid grid-cols-2 text-md xl:block">
                            <p className={`bg-neutral-200 px-8 py-10 text-md font-bold ${variantIndex === 0 ? '' : 'xl:hidden'}`}>
                                {attribute.label}
                            </p>
                            <div className="ml-8 overflow-hidden px-6 py-8 xl:ml-0">{attribute.value}</div>
                        </div>
                    ))}
                </div>
            ))}
            {needsSelection && (
                <Typography className="text-right text-red-500" as="p">
                    {formatErrorMessage({ id: 'error.bundle.select.one', defaultMessage: 'Select one of the variants' })}
                </Typography>
            )}
        </div>
    );
};

export default BundleComponentSelectorTile;
