import React from 'react';
import Typography from 'components/commercetools-ui/atoms/typography';

export interface Props {
    prop?: any; // TODO remove if not used
}

const CreditCartFields: React.FC<Props> = ({ prop }) => {
    console.log('---> prop', prop);

    return (
        <div className="border-checkout-border  border-b pb-16 lg:border-0 lg:pb-15">
            <Typography className="mb-16 text-20" as="h2">
                Credit / Debit cart
            </Typography>

            <p>cc form</p>
        </div>
    );
};

export default CreditCartFields;
