import React from 'react';
import type { FC } from 'react';
import Button from 'components/commercetools-ui/atoms/button';
import Modal from 'components/commercetools-ui/atoms/modal';

export interface Props {
    isOpen: boolean;
    closeModal: () => void;
    errorMessage: string;
}

const ErrorModal: FC<Props> = ({ isOpen, closeModal, errorMessage }) => {
    return (
        <Modal
            isOpen={isOpen}
            className="relative w-[90%] rounded-md bg-white"
            style={{ content: { maxWidth: '400px' } }}
            preventScroll={true}
        >
            <div className="flex flex-col gap-25 p-15 text-base leading-6 md:gap-20 md:p-40">
                <p className="mb-10 text-center">{errorMessage}</p>

                <Button variant="warning" size="full" onClick={closeModal} className="rounded-md md:rounded-lg">
                    I understand
                </Button>
            </div>
        </Modal>
    );
};

export default ErrorModal;
