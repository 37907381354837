import type { FC } from 'react';
import React from 'react';
import { useFormat } from 'helpers/hooks/useFormat';
import Checkbox from 'components/commercetools-ui/atoms/checkbox';
import { useCart } from 'frontastic';

interface DelegateCheckboxProps {
    itemId: string;
}

const DelegateCheckbox: FC<DelegateCheckboxProps> = ({ itemId }) => {
    const { delegateObj, handleIsDelegateChange } = useCart();
    const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });

    return (
        <div className="mt-12">
            <Checkbox
                smallLabel={true}
                label={formatCartMessage({ id: 'delegate.checkbox.label', defaultMessage: 'I am the delegate' })}
                name={`${itemId}_isDelegate`}
                onChange={({ checked }) => {
                    handleIsDelegateChange(itemId, checked);
                }}
                checked={delegateObj?.[itemId] === true}
            />
        </div>
    );
};

export default DelegateCheckbox;
