import type { ChangeEvent, ComponentProps, FC } from 'react';
import React, { useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import useClassNames from 'helpers/hooks/useClassNames';
import Typography from '../typography';

export interface DropdownProps extends ComponentProps<'select'> {
    className?: string;
    containerClassName?: string;
    labelClassName?: string;
    items: { label: string; value: string }[];
    label?: string;
    value?: string;
    defaultValue?: string;
    errorMessage?: string;
}

const DefaultDropdown: FC<DropdownProps> = ({
    className = '',
    containerClassName = '',
    labelClassName,
    label,
    items,
    onChange,
    value,
    errorMessage,
    ...props
}) => {
    const [selectedValue, setSelectedValue] = useState(value);

    const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        setSelectedValue(value);
        onChange?.(e);
    };

    const containerClassNames = useClassNames(['grid', containerClassName]);

    const selectClassName = useClassNames([
        'absolute z-[1] h-45 w-full  rounded-md border-none bg-transparent bg-none py-0 pl-12 pr-0 font-body leading-loose outline-neutral-200 focus:ring-neutral-500 disabled:text-input-disabled',
        className
    ]);

    const labelClassNames = useClassNames(['mb-[11px] text-md font-label leading-loose', labelClassName]);

    return (
        <div className={containerClassNames} data-error={!!errorMessage}>
            {label && (
                <Typography as="label" htmlFor={props.name} className={labelClassNames}>
                    {`${label}${props.required ? ' *' : ''}`}
                </Typography>
            )}

            <div className={`relative h-45 min-w-64 rounded-md border  ${errorMessage ? 'border-input-error' : 'border-input-border'}`}>
                <select className={selectClassName} value={value ?? selectedValue} onChange={handleChange} id={props.name} {...props}>
                    {items.map(({ label, value }) => (
                        <option key={label} value={value}>
                            {label}
                        </option>
                    ))}
                </select>

                <ChevronDownIcon className="absolute right-5 top-[50%] z-0 h-20 w-30 translate-y-[-50%] stroke-1" />
            </div>
            {errorMessage && (
                <Typography className="mt-12 text-sm leading-tight text-input-error" as="p">
                    {errorMessage}
                </Typography>
            )}
        </div>
    );
};

export default DefaultDropdown;
