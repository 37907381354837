import { FieldType } from '@wilm/shared-types/validation-rules/types';
import type { Fields, StringFieldDefinition } from '@wilm/shared-types/validation-rules/types';

export interface PoNumber extends Fields {
    poNumber: StringFieldDefinition;
}

export const fields: PoNumber = {
    poNumber: {
        name: 'poNumber',
        type: FieldType.STRING,
        value: '',
        validation: {
            required: true,
            minLength: 2,
            maxLength: 50,
            errorMessages: {
                validation: 'error.missing.poNumber',
                range: 'error.range.poNumber'
            }
        }
    }
};
